$fade-color: #d6e6c1;
$primary-color: #28a95e;
$secondary-color: #75b519;

@import '../../../node_modules/menu-galaxy/dist/index.scss';


@import "../../../node_modules/menu-galaxy/dist/variables.scss";

$logo-url-white: "/assets/img/logo/logo-super-clea-num-neg.svg";
$logo-url: "/assets/img/logo/logo-super-clea-num.svg";
$main-color: #28a95e;
$secondary-color: #75b519;
$background-color-1: #28a95e;
$background-color-2: #75b519;

$third-color: #DEAF4E;

@import "../../../node_modules/menu-galaxy/dist/stylesheets/_custom.scss";

.custom-btn {
  font-size: 12px;
}

#navigation {
  .home, &.glxy-btn-home {
    background: none;
  }
}

#banner {
  .additional-menu {
    & > ul {
      margin: auto!important;
      & > li {
        &:nth-child(2) {
          &:not(:last-child) {
            &.row-level-2 {
              & > a {
                @extend .reverse;
              }
            }
          }

        }
        &.row-level-2 {
          a {
            @media screen and (max-width: 719px) {
              color: $secondary-color;
            }
          }
        }

      }
    }

  }
  #digi-news {
    .typer {
      width: 220px;
    }
    @media screen and (max-width: 900px) {
      strong, .typer {
        display: none;
      }
    }
    button {
      background-color: $background-color-1;
    }
  }
  .glxy-loader {
    border-top-color: $secondary-color;
  }

  #banner .galaxy-search {
    margin-top: -5px;
  }
}
