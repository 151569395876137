$secondary-color: #00f2f2;

@import '../../../../../node_modules/@dsk-lib/quizplayer/build/styles/common.style.scss';

$primary-color: #75b519;
$title-color: $primary-color;
$font-color: #1b1b1d;
$font-size: 18px;
$input-font-size: 15px;
$border-color: #c8cdd7;
$line-height: 46px;
$valid-color: #e3f5a2;
$valid-color-dark: #95ba0d;
$invalid-color: #ffc0b8;
$invalid-color-dark: #f0351d;
$image-width: 600px;

#digi-quiz-player {

  .loadImg {
    &.loaded {
      display: none;
    }
  }

  .image-container {
    img {
      width: 100%;
      height: auto;
    }
  }

  #digi-quiz-player {
    .missing-word {
      .missing-word-correction {
        ul {
          border-radius: 4px !important;

          li {
            &.invalid {
              color: $invalid-color-dark !important;
            }

            color: $valid-color-dark !important;
          }
        }

      }
    }
  }

  .quiz-player {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    min-width: 270px;

    *, *:before, *:after {
      box-sizing: border-box;
    }

    &.finish {
      .quiz-header {
        display: none;
      }

      #quiz {
        #quiz-content {
          .player-container {
            #quiz-information {
              .score-container {
                display: none;
              }

              &.hidden {
                visibility: visible !important;
              }
            }
          }
        }
      }
    }

    color: $font-color;

    .quiz-header {
      position: relative;
      padding-top: 20px;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;

      .question-counter {
        text-align: center;
        font-size: 14px;
        font-weight: bold;

        &:after {
          content: '';
          display: block;
        }
      }

      .progress-bar {
        margin: 0 auto 15px auto;
        width: 100%;
        max-width: 310px;
        height: 7px;

        .progress-bar-background, .progress-bar-line {
          height: 100%;
          border-radius: 7px;
        }

        .progress-bar-background {
          margin: auto;
          background: rgba(0, 0, 0, 0.10);

          .progress-bar-line {
            background-color: $title-color;
          }
        }
      }
    }

    #quiz {
      position: relative;

      #quiz-content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media all and (max-width: 754px) {
          flex-direction: column;
        }

        .subquestion-container {
          .digi-media {
            img {
              max-width: 100%;
            }
          }
        }

        #question-media-container {
          //min-width: $image-width / 2;

          margin-bottom: 20px;

          .medias {
            .media {
              position: relative;

              img, video, audio {
                max-width: 100%;
                height: auto;
              }

              .digi-media {
                position: relative;
                margin: auto;

                &.zoom {
                  cursor: pointer;

                  .loadImg {
                    transition: opacity 1s ease-in-out;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: #ffffff;
                    opacity: 1;

                    img {
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                    }

                    &.loaded {
                      opacity: 0;
                    }
                  }

                  img {
                    display: block;
                    transition: opacity 1s ease-in-out;
                  }

                  &:after {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    display: block;
                    content: '';
                    width: 63px;
                    height: 63px;
                    background-color: rgba(0, 0, 0, 0.3);
                    // background-image: url(../../public/static/img/zoom.png);
                    background-repeat: no-repeat;
                    background-position: 65% 65%;
                    border-top-left-radius: 63px;
                  }
                }
              }
            }
          }
        }

        .player-container {
          width: 100%;
          margin: auto;
          max-width: $image-width;
          padding: 0 20px;

          #quiz-information {
            color: $font-color;
            display: flex;
            flex-direction: row;
            font-size: 14px;

            #timer {
              margin-left: 10px;
              color: $title-color;
              font-size: 20px;

              &:before {
                display: inline-block;
                content: '';
                width: 18px;
                height: 20px;
                // background: url(../../public/static/img/ic-chrono.svg) no-repeat;
                background-size: cover;
                margin-right: 5px;
              }
            }

            .score-container {
              flex: 1;
              font-weight: bold;

              .score-number {
                color: $title-color;

                .separator {
                  background: none;
                }
              }

              &.finish {
                display: none;
              }
            }

            .result {
              flex: 1;

              justify-content: flex-end;
              text-align: right;
              margin-top: 0;

              &.invalid {
                color: $invalid-color-dark;
              }

              &.valid {
                color: $valid-color-dark;
              }
            }

            @media all and (max-width: 380px) {
              display: block;
              #timer, .sound-off, .sound-off, .score-container {
                display: inline-block;
              }
              .result {
                display: block;
                width: 100%;
                text-align: center;
              }
            }

          }

          #quiz-information, #question-container, .subquestion-container {
            margin: auto;
            max-width: $image-width;
            flex: 1;
          }

          #question-container, .subquestion-container {
            font-size: $font-size;
            font-weight: 300;
            line-height: 1.2em;

            .question-text {
              text-align: left;
              margin: 10px 0;
            }

            .subquestion {
              font-size: 16px;
              margin: 20px 0 10px 0;
              font-weight: normal;

              .subquestion-content {
                margin-bottom: 10px;
              }

              p {
                position: relative;
                margin: 0;
              }

              .missing-word {
                font-size: 18px;
                line-height: 2.22;

                input[type=text] {
                  margin: 0 2px;
                  padding: 0 5px;
                  text-align: center;
                  border-radius: 100px;
                  border: solid 1px $title-color;
                  height: 36px;
                  font-size: 18px;
                  font-weight: 300;

                  &:focus {
                    outline: none;
                  }
                }

                ::placeholder {
                  font-style: italic;
                  color: #9297a2;

                }

                .missing-word-correction {
                  ul {
                    border-radius: 18px;
                    background-color: #ffffff;
                    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05), inset 1px 0 0 0 rgba(0, 0, 0, 0.11);
                    border: solid 1px #c3c6cc;
                    padding: 5px 15px;

                    &.valid {
                      border-color: $valid-color;
                    }

                    li {
                      color: $valid-color;
                      font-size: 18px;
                      font-weight: normal;

                      &.invalid {
                        text-decoration: line-through;
                        color: $invalid-color;
                        font-size: 14px;
                      }
                    }
                  }
                }
              }

              .Select {
                &.is-open {
                  .Select-control {
                    .Select-arrow {
                      margin-top: 5px;
                      transform: rotate(-135deg);
                    }
                  }
                }

                .Select-menu-outer {
                  border-radius: 18px;
                  background-color: #ffffff;
                  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05), inset 1px 0 0 0 rgba(0, 0, 0, 0.11);
                  border: solid 1px $title-color;
                  overflow: hidden;
                  top: 0;
                }

                .Select-control {
                  border-radius: 100px;
                  border: solid 1px $title-color;

                  .Select-value {
                    .Select-value-label {
                      color: $title-color;
                    }
                  }

                  .Select-arrow {
                    margin-top: -5px;
                    vertical-align: middle;
                    padding: 4px;
                    border: solid $title-color;
                    border-width: 0 1px 1px 0;
                    transform: rotate(45deg);
                  }
                }
              }

              .free-text-answer {
                margin-top: 20px;

                input[type=text] {
                  width: 100%;
                  min-height: $line-height;
                  border-radius: 6px;
                  border: solid 1px $border-color;
                  background-color: #ffffff;
                  font-size: $input-font-size;
                  padding: 0 10px;

                  &:focus {
                    outline: none;
                  }
                }

                .free-text-correction {
                  list-style: none;
                  margin: 0;
                  padding: 0;

                  li {
                    margin-bottom: 10px;

                    .invalid {
                      border-color: $invalid-color;
                      color: $invalid-color;
                    }

                    .free-text-correction {
                      border-color: $valid-color;
                    }
                  }
                }
              }

              .answers-container {
                margin: 20px 0;
                list-style: none;
                padding: 0;

                .answer {
                  overflow: initial !important;

                  &.with-media {

                  }
                }

                .answer-container {
                  cursor: pointer;
                  position: relative;
                  margin-top: 10px;
                  border-radius: 4px;
                  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
                  padding: 0 15px;
                  margin-left: 0;
                  font-size: $input-font-size;
                  font-weight: normal;
                  color: #363a43;
                  background-color: #ffffff;
                  min-height: 60px !important;
                  border: none;

                  &.selected, &:hover {
                    background-color: rgba($primary-color, 0.1);

                    &:not(.invalid, .valid) {
                      border-color: black;
                    }
                  }

                  &.selected {
                    font-weight: bold;

                    .response {
                      font-weight: bold;
                    }
                  }

                  .response-status {
                    height: 100%;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    background: none;
                  }

                  &.invalid {
                    background-color: $invalid-color;

                    .response {
                      padding-right: 20px;
                    }

                    .title, .letter {
                      color: $invalid-color-dark;
                    }

                    &.selected {
                      font-weight: bold;

                      .response-status {
                        background: none;

                        &:after {
                          content: '';
                          display: block;
                          // background: url('../../public/static/img/ic-wrong.svg') no-repeat center;
                          height: 100%;
                          width: 46px;
                        }
                      }
                    }
                  }

                  &.valid {
                    padding-right: 35px;
                    font-weight: bold;
                    background: #ffffff;

                    .title {
                      color: $valid-color-dark;
                      font-weight: normal;
                    }

                    .response {
                      padding-right: 20px;
                    }

                    border: 1px solid $valid-color-dark;

                    .letter {
                      color: $valid-color-dark;
                    }

                    &.selected {
                      background: $valid-color;
                      border: none;

                      .title {
                        font-weight: bold;
                      }

                      .response-status {
                        background: none;

                        &:after {
                          content: '';
                          display: block;
                          // background: url('../../public/static/img/ic-right.svg') no-repeat center;
                          height: 100%;
                          width: 46px;
                          filter: none;
                        }
                      }
                    }

                    .response-status {
                      background: none;

                      &:after {
                        content: '';
                        display: block;
                        // background: url('../../public/static/img/ic-miss-right.svg') no-repeat center;
                        height: 100%;
                        width: 46px;
                        filter: none;
                      }

                    }
                  }

                  .letter, .response {
                    padding: 20px 0;
                  }

                  .letter {
                    position: absolute;
                    color: $primary-color;
                    height: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    font-weight: bold;

                    &:after {
                      content: '';
                      margin: 0 12px 0 9px;
                      vertical-align: middle;
                      display: inline-block;
                      height: 100%;
                      width: 1px;
                      background-color: #000000;
                    }
                  }

                  .response {
                    text-align: left;
                    padding-left: 35px;
                    font-weight: normal;
                  }
                }
              }
            }
          }
        }
      }

      .quiz-bottom {
        margin: auto;
        width: 100%;
        padding: 0 20px;
        min-height: 80px;

        .button-wrapper {
          max-width: $image-width;
          margin: auto;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .btn-explanation-wrapper {
            margin: auto;

            .btn-explanation-quiz {
              @extend .quiz-btn;
              border: 1px solid $title-color !important;
              background-color: initial !important;
              color: $title-color !important;
              min-width: 200px;

              &:after {
                content: none;
              }
            }
          }

          .btn-next-wrapper {
            position: relative !important;
            top: initial !important;
            margin: auto;

            .btn-next {
              @extend .quiz-btn;

              &.arrow {
                &:after {
                  content: "";
                  border: solid white;
                  border-width: 0 2px 2px 0;
                  display: inline-block;
                  padding: 4px;
                  transform: rotate(-45deg);
                }
              }

              &[disabled] {
                cursor: default;
                background: #c3c3c3;
              }
            }
          }

          @media all and (max-width: 382px) {
            flex-direction: column;
            .btn-next, .btn-explanation-quiz {
              width: 100%;
            }
            .btn-explanation-quiz {
              margin-bottom: 10px;
            }
            .btn-next-wrapper {
              margin-left: 0;
            }
          }
        }
      }
    }

    .score-container {
      &.finish {
        text-align: center;
        font-size: 28px;

        &:after {
          content: '';
          display: block;
          width: 30px;
          height: 2px;
          margin: 30px auto;
          background-color: $title-color;
        }

        .current-score-label {
          display: none;
        }

        .current-score {
          font-size: 69px;
          font-weight: bold;
          color: $title-color;
        }

        .total-score, .separator {
          font-size: 58px;
          color: #18202f;
        }
      }

    }

    .correction-container {
      position: relative;

      width: 460px;
      margin: 20px auto;

      .page-control {
        cursor: pointer;
        width: 36px;
        height: 36px;
        background-color: #ffffff;
        border: solid 1px #c8cdd7;
        border-radius: 18px;
        text-align: center;
        padding-top: 7px;
        overflow: hidden;
        font-size: 16px;

        &.prev {
          position: absolute;
          top: 0;
          left: -46px;
        }

        &.next {
          position: absolute;
          top: 0;
          right: -36px

        }
      }

      #correction {
        position: relative;
        display: inline-block;
        height: 36px;
        overflow: hidden;
        width: 460px;

        ul {
          position: absolute;
          transition: left 1s ease-out;
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            display: inline-block;
            margin-left: 0;

            &:not(:last-child) {
              margin-right: 10px;

            }

            &.questionSelect {

              color: $valid-color-dark;
              border-color: $valid-color-dark;

              &.false {
                color: $invalid-color-dark;
                border-color: $invalid-color-dark;
              }

              &:hover, &.active {
                background-color: $valid-color-dark;
                border-color: $valid-color-dark;

                &.false {
                  background-color: $invalid-color-dark;
                  border-color: $invalid-color-dark;
                  color: #ffffff;
                }

                color: #ffffff;
              }
            }

          }
        }
      }

      @media all and (max-width: 575px) {
        height: 20px;
        width: 230px;
        #correction {
          width: 230px;
        }
      }
    }

  }
}

.quiz-btn {
  display: inline-block;
  cursor: pointer;
  border: none;
  outline: none;
  min-width: 164px;
  height: 46px;
  border-radius: 23px;
  background-color: $title-color;
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
  padding: 0 20px;
}

.ReactModal__Overlay {
  position: fixed !important;
}

.ReactModal__Content {
  margin: 10% auto;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.ReactModalPortal {
  .quiz-player-explanation {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05), inset 1px 0 0 0 rgba(0, 0, 0, 0.11);
    background-color: white;
    border-radius: 2px;
    padding: 30px 39px;
    color: $font-color;
    max-width: 550px;
    font-size: 14px;
    text-align: left !important;

    &:focus {
      outline: none;
    }

    h5 {
      padding: 0;
      text-align: center;
      font-size: 30px;
      color: #282828;
      font-weight: 100;
      margin: 0;

      &:after {
        content: '';
        display: block;
        width: 130px;
        height: 4px;
        margin: 30px auto;
        background-color: $title-color;
      }
    }

    p {
      color: #767b7d;
    }

    .modal-explanation-footer {
      text-align: center;

      .modal-explanation-btn-close {
        margin-top: 30px;
        @extend .quiz-btn;
      }
    }

  }
}


